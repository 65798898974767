<template>
  <div  class="footer">
      <div class="context">
          <p>
              <br>
              <br>
              版权所有 @ 2021-2024
          </p>
      </div>
  </div>
</template>

<script>
export default {
    name: 'Footer',
    data (){
        return {

        }
    }
}
</script>

<style scoped>

.footer{
    height: 70px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 14px;
}
.context{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
a {
    text-decoration: none;
    color:#0088CC;
}
</style>
