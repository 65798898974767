import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(ElementUI);
Vue.use(VXETable)

Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    render: (h) => {
        return h(App);
    },
}).$mount("#app");
