<template>
  <div class="navigation-container" style="text-align: center">
    <nav class="nav" style="text-align: center;margin-left: 50%">
        <RouterLink
            v-for="item in items"
            :key="item.link"
            :to="{name: item.name}">
            <span class="title">
                {{ item.title }}
            </span>
        </RouterLink>
    </nav>
  </div>
</template>

<script>
export default {
    data(){
    return {
      items: [
        {
          name: 'Home',
          title: "平台咨询",
        }
      ]
    }
  },
  components:{

  },
}
</script>

<style scoped lang="less">
.navigation-container{
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #efaeae 0%, #b69a5e 40%, #8c3535 150%), linear-gradient(to top, rgb(165, 226, 248) 0%, rgba(0,0,0,0.25) 200%);
    background-blend-mode: multiply;
}
.nav{
    width: 70%;
    height: 100%;
    margin: 0 15%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    text-align: center;
}
a{
    font-size: 15px;
    font-weight: bold;
    padding: 0 25px;
    display: flex;
    align-items: center;
    color: lighten(lightgray, 5%);
}
span{
  white-space: nowrap;
}
a:hover{
    color: #fff;
    transform: scale(1.3);
}
</style>
