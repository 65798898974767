import Vue from 'vue';
import Router from 'vue-router';
import HomePage from '@/views/project';

Vue.use(Router)

export default new Router({
    routes: [{
            name: 'Home',
            path: '/', //首页
            components: {
                default: HomePage,
            }
        },
    ],
    mode: 'hash'
})
