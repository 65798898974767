<template>
  <div class="joinus-container">
<!--    <Content
      :item="item">
    </Content>-->

    <div class="container">
    <div style="text-align: center">
      <el-table border height="100%"  :data="tableData">
          <el-table-column label="平台名称" width="300" align="center" prop="date">
            <template slot-scope="scope">
              <img v-if="scope.row.date == '凤凰'" :src="AI" style="width: 100px;height: 25px;margin-top: 10px">
              <img v-if="scope.row.date == '米兰'" :src="milan" style="width: 100px;height: 30px;margin-top: 10px">
              <img v-else-if="scope.row.date == '川D彩乐园'" :src="cai" style="width: 50px;height: 30px;margin-top: 10px">-
              <span style="color: blue;font-weight: bold;font-size: 20px">{{scope.row.date}}</span>
            </template>
          </el-table-column>
        <el-table-column label="平台类型" width="300" align="center" prop="name">
          <template slot-scope="scope">
            <span v-if="scope.row.name == '新品牌'" style="color: #12c03b;font-weight: bold;font-size: 20px">{{scope.row.name}}</span>
            <span v-else style="color: blue;font-weight: bold;font-size: 20px">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="信誉等级" width="300" align="center" prop="address">
          <template slot-scope="scope">
            <span v-if="scope.row.address == 'AAAAA'" style="color: #12c03b;font-weight: bold;font-size: 20px">{{scope.row.address}}</span>
            <span v-else-if="scope.row.address == 'AAAA'" style="color: #18828c;font-weight: bold;font-size: 20px">{{scope.row.address}}</span>
            <span v-else style="color: #ceb63e;font-weight: bold;font-size: 20px">{{scope.row.address}}</span>
          </template>
        </el-table-column>
        <el-table-column label="平台地址" align="center"  class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
                size="small"
                type="text"
                v-if="scope.row.url != ''"
            >      <a target="_blank" :href="scope.row.url" style="color: #1b42c0;font-size: 20px">点击立即前往>></a></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    </div>
  </div>
</template>

<script>
import Content from '@/components/Content'
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
 components: {
   Content,
 }
 ,
 data () {
   return {
     AI:require('@/assets/homepage/fengh.png'),
     cai:require('@/assets/homepage/cail.png'),
     milan:require('@/assets/homepage/milan.png'),
     tableData: [
       {date: '凤凰', name: '老品牌',address: 'AAAAA',url: 'https://www.fhfh5238.com/'},
       {date: '川D彩乐园', name: '老品牌',address: 'AAAAA',url: 'https://www.ip5010.co/?a=42458'},
       {date: '米兰', name: '新品牌',address: 'AAAAA',url: 'http://mlbet416.vip'},
       {date: '九游', name: '新品牌',address: 'AAA',url: ''},
       {date: '星空', name: '新品牌',address: 'AAA',url: ''},
       {date: 'OD乐竟', name: '新品牌',address: 'AAA',url: ''},
       {date: '乐竟', name: '新品牌',address: 'AAA',url: ''},
       {date: '开云', name: '新品牌',address: 'AAAA',url: ''},
     ],
     item : "加入我们",
     introduction: "一个专注彩票事业二十年的团队",
   }
 },
  methods: {
  }
}
</script>

<style scoped>
.container{
  width: 70%;
  margin: 0px 15%;
}
.page-header{

  padding-bottom: 9px;
  padding-top: 9px;
  margin: 20px 0px 30px;
}
p{
  white-space: normal;
}

span{
  font-weight:bold;
}
li{
  line-height:25px;
}

section{
  background-color: #468dc7;
  border-radius: 5px;
  color: #eeeeee;
  text-align: center;
  width:65%;
  margin: 0 auto;
}
</style>
