<template>
  <div id="app"  style="font-family: 楷体;" ref="vantaRef">
    <div ></div>
    <Layout>
      <template v-slot:top>
        <Navigation />
      </template>
      <template v-slot:default>
        <router-view></router-view>
      </template>
      <template v-slot:bottom>
        <Footer />
      </template>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/components/Layout";
import Footer from "@/components/footer/Footer";
import Navigation from "@/components/Navigation"
import * as THREE from 'three'
import Net from 'vanta/src/vanta.net'
export default {
  components: {
    Layout,
    Footer,
    Net,
    Navigation
  },
  name: 'App',
  mounted() {
    this.vantaEffect = Net({
      el: this.$refs.vantaRef,
      THREE: THREE
    })

    VANTA.NET({
      el: this.$refs.vantaRef,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x9c516c,
      backgroundColor: 0xa592c5,
      points: 12.00,
      maxDistance: 23.00,
      spacing: 17.00
    })


  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  },
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
</style>
